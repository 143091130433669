import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import "./App.scss";

import Registration from "./pages/auth/Registration.page";
import Login from "./pages/auth/Login.page";

import AnnotatorTaskPage from "./pages/annotation/AnnotatorTask.page";
import WaveSurferComPage from "./pages/annotation/WaveSurferCom.page";

import AdminTaskPage from "./pages/admin/AdminTaskPage";
import AdminViewEdit from "./pages/admin/AdminViewEdit";
import AdminMonitor from "./pages/admin/AdminMonitor";
import RoleUpdate from "./pages/admin/RoleUpdate";

import {ReactNotifications} from "react-notifications-component";
import { isLoggedIn } from "./common/http";


function App() {
  const [loginStatus, setLoginStatus]: any = useState({
    loginStatus: isLoggedIn(),
  });
  
  useEffect(() => {
    // let status = isLoggedIn();
    // console.log('isLoggedIn', loginStatus);
    // setLoginStatus(status)
  }, []);

  return (
    <div className="App">
      <ReactNotifications />
      {/*<Application />*/}
      <Router basename={process.env.REACT_APP_ROUTER_BASE || ""}>
        {/*<Router>*/}
        <Switch>
          {loginStatus ? (
            <>
              <Route exact path="/" component={Login} />
              <Route path="/registration" component={Registration} />
              <Route path="/login" component={Login} />

              <Route path="/annotation-task" component={AnnotatorTaskPage} />
              <Route path="/annotation" component={WaveSurferComPage} />

              <Route path="/admin" component={AdminTaskPage} />
              <Route path="/roles" component={RoleUpdate} />
              <Route path="/admin-monitor" component={AdminMonitor} />
              <Route path="/admin-view-edit" component={AdminViewEdit} />
            </>
          ) : (
            <Login />
          )}
        </Switch>
      </Router>
    </div>
  );
}

export default App;

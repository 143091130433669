import React, { FC, ReactNode, useEffect, useState, useRef, useMemo, useContext, createContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';

import { get, isLoggedIn, post, postToPython, setToken, decodeToken, getLoggedInUserId, unsetToken } from '../../common/http';

import MaterialReactTable, { MRT_ColumnDef, MRT_Cell, MRT_Column, MRT_Row } from 'material-react-table';
import type {
    ColumnFiltersState,
    PaginationState,
    SortingState,
    VisibilityState,
} from '@tanstack/react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ToggleButton from '@mui/material/ToggleButton';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import FunctionsRoundedIcon from '@mui/icons-material/FunctionsRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';

import { Button, CardActionArea, CardActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { styled, lighten, darken } from '@mui/system';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import Badge from '@mui/material/Badge';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg, ToastInfoMsg } from "../../common/toast";
import { red } from '@mui/material/colors';

// import activity from '../../icons/activity.png';

import { Link, useHistory, useParams } from "react-router-dom";
import $ from 'jquery';

const ColorModeContext = createContext({ toggleColorMode: () => { } });
// const { useSpeechSynthesis } = require("react-speech-kit");

// const filterEligibleValidators = [
//     "mina@revesoft.com",
//     "validatortts@gmail.com"
// ]

const privilegedValidators = [
    "validator@gmail.com", "validatortts@gmail.com",
    "shahnewaz.reve@gmail.com",
    "shahnewaz_tts@gmail.com",
    "mobasshera.islam@gmail.com",
    "mohiuddinmia15@gmail.com",
    "sanzidakter092@gmail.com",
    "iammehedihasanrakib1516@gmail.com",
    "validator_main@crebsol.com",
    "validator_test@crebsol.com"
];

const sttEvalAnnotators = [
    "annotatorstteval@gmail.com",
    "mohiuddinmia14@gmail.com",
    "Mdmehedihasanrakib44@gmail.com",
    "shahinurling.du@gmail.com"
];

type Task = {
    speech_id: string;
    duration: number;
    size: number;
    level: number;
    distribution: string;
    distribution_source: string,
    no_of_times_annotated: number;
};

type TaskHistoryAnnotator = {
    speech_id: string;
    duration: number;
    timeTaken: number;
    annotatedDuration: number;

    validated: string;
    validatedDuration: number;
    // overlappedDuration: number;

    verified: string;
    verifiedDuration: number;
    deductedDuration: number;
    acceptedDuration: number;

    submittedOn: string;
    // billingStatus: string;
    level: string;
};

type TaskHistoryValidator = {
    speech_id: string;
    duration: number;
    timeTaken: number;
    validatedDuration: number;
    // overlappedDuration: number;

    verified: string;
    verifiedDuration: number;
    deductedDuration: number;
    acceptedDuration: number;

    submittedOn: string;
    // billingStatus: string;
    level: string;
};

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const pages = [
    // 'Registration',
    // 'Home',
    'Logout'
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        minWidth: 250,
        maxWidth: 500,
        maxHeight: 300,
        overflowX: 'auto',
        overflowY: 'auto'
    },
});

const formatHourMinSec = (totalSeconds: number) => {
    let hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
    let seconds = (hours > 0 || minutes > 0) ? Math.round(totalSeconds - hours * 3600 - minutes * 60) : parseFloat((totalSeconds - hours * 3600 - minutes * 60).toFixed(3))

    // return `${("0" + hour).slice(-2)} hr ${("0" + minutes).slice(-2)} min ${("0" + seconds).slice(-2)} sec`
    return `${hours > 0 ? hours + ' hr ' : ''}${minutes > 0 ? minutes + ' min ' : ''}${seconds > 0 ? seconds + ' sec' : ''}`
}

const onLogout = () => {
    // setUnloadEventListener(false);

    // setTimeout(() => {
    localStorage.removeItem('speech_id');
    localStorage.removeItem('latestPage');
    localStorage.removeItem('latestSizePerPage');
    unsetToken();
    // history.push('/login')
    // history.go(0)
    window.location.replace(window.location.origin + `/login`)
    // }, 100);
    // return true;
};

const rowEvents = {
    // onClick: (e:any, row:any, rowIndex:any) => {
    //   console.log("clicked on: ", row, rowIndex, e);
    // },
    // onMouseEnter: (e:any, row:any, rowIndex:any) => {
    //     console.log("entered on: ", row, rowIndex, e);
    // }
};

const AnnotatorTaskPage: FC = () => {
    //data and fetching state
    // useEffect(() => {
    //     let utterance = new SpeechSynthesisUtterance("Hello world!");
    //     window.speechSynthesis.speak(utterance);
    //     console.log(window.speechSynthesis.getVoices())
    // }, [])

    const userInfo: any = useRef(decodeToken());
    const [dataTask, setDataTask] = useState<Task[]>([]);
    const [dataHistoryAnnotator, setDataHistoryAnnotator] = useState<TaskHistoryAnnotator[]>([]);
    const [dataHistoryValidator, setDataHistoryValidator] = useState<TaskHistoryValidator[]>([]);

    const firstRender = useRef(true);
    const [targetAnnotatorId, setTargetAnnotatorId] = useState(localStorage.getItem("targetAnnotatorId") ? localStorage.getItem("targetAnnotatorId") : "");
    const verificationStatsDict: any = useRef({});

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    // table state
    // const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(localStorage.getItem('columnVisibility') ? JSON.parse(localStorage.getItem('columnVisibility')) : { distribution: false, distribution_source: false, no_of_times_annotated: false });
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({ distribution: false, distribution_source: false, no_of_times_annotated: false });

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(localStorage.getItem('columnFilters') ? JSON.parse(localStorage.getItem('columnFilters')) : []);

    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(localStorage.getItem('globalFilter') ? JSON.parse(localStorage.getItem('globalFilter')) : undefined);

    const [sorting, setSorting] = useState<SortingState>(localStorage.getItem('sorting') ? JSON.parse(localStorage.getItem('sorting')) : []);

    const [pagination, setPagination] = useState<PaginationState>(localStorage.getItem('pagination') ? JSON.parse(localStorage.getItem('pagination')) : {
        pageIndex: 0,
        pageSize: 10,
    });
    // localStorage.getItem('columnVisibilityHistory') ? JSON.parse(localStorage.getItem('columnVisibilityHistory')) : 
    const [columnVisibilityHistory, setColumnVisibilityHistory] = useState<VisibilityState>({ timeTaken: false });
    // const [columnFiltersHistory, setColumnFiltersHistory] = useState<ColumnFiltersState>(localStorage.getItem('columnFiltersHistory') ? JSON.parse(localStorage.getItem('columnFiltersHistory')) : []);
    const [columnFiltersHistory, setColumnFiltersHistory] = useState<ColumnFiltersState>([]);

    const [globalFilterHistory, setGlobalFilterHistory] = useState(localStorage.getItem('globalFilterHistory') ? JSON.parse(localStorage.getItem('globalFilterHistory')) : undefined);
    const [sortingHistory, setSortingHistory] = useState<SortingState>(localStorage.getItem('sortingHistory') ? JSON.parse(localStorage.getItem('sortingHistory')) : []);
    const [paginationHistory, setPaginationHistory] = useState<PaginationState>(localStorage.getItem('paginationHistory') ? JSON.parse(localStorage.getItem('paginationHistory')) : {
        pageIndex: 0,
        pageSize: 10,
    });

    const levels = ['SENTENCE', 'WORD', 'TRANSCRIPTION', 'PHONEME']
    const distributions = ['READ', 'CONVERSATION_MONO', 'CONVERSATION_DI', 'CONVERSATION_MULTI', 'LECTURE', 'SPONTANEOUS', 'MISCELLANEOUS']
    const distribution_sources = ['CREATE', 'COLLECT']
    const billing_status_list = ["UNPAID", "PROCESSING", "PAID"]

    const sumAnnotatedDuration = useMemo(
        () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.annotatedDuration, 0),
        [dataHistoryAnnotator],
    );

    const sumValidatedDurationAnt = useMemo(
        () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.validatedDuration, 0),
        [dataHistoryAnnotator],
    );

    const sumValidatedDuration = useMemo(
        () => dataHistoryValidator.reduce((acc, curr) => acc + curr.validatedDuration, 0),
        [dataHistoryValidator],
    );

    // const sumOverlappedDurationAnt = useMemo(
    //     () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.overlappedDuration, 0),
    //     [dataHistoryAnnotator],
    // );

    // const sumOverlappedDurationVal = useMemo(
    //     () => dataHistoryValidator.reduce((acc, curr) => acc + curr.overlappedDuration, 0),
    //     [dataHistoryValidator],
    // );

    const sumVerifiedDurationAnt = useMemo(
        () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.verifiedDuration, 0),
        [dataHistoryAnnotator],
    );

    const sumVerifiedDurationVal = useMemo(
        () => dataHistoryValidator.reduce((acc, curr) => acc + curr.verifiedDuration, 0),
        [dataHistoryValidator],
    );

    const sumDeductedDurationAnt = useMemo(
        () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.deductedDuration, 0),
        [dataHistoryAnnotator],
    );

    const sumDeductedDurationVal = useMemo(
        () => dataHistoryValidator.reduce((acc, curr) => acc + curr.deductedDuration, 0),
        [dataHistoryValidator],
    );

    const sumAcceptedDurationAnt = useMemo(
        () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.acceptedDuration, 0),
        [dataHistoryAnnotator],
    );

    const sumAcceptedDurationVal = useMemo(
        () => dataHistoryValidator.reduce((acc, curr) => acc + curr.acceptedDuration, 0),
        [dataHistoryValidator],
    );

    const medianTimeTakenAnt = useMemo(
        () => {
            if (dataHistoryAnnotator.length == 0) { return 0; }
            const mid = Math.floor(dataHistoryAnnotator.length / 2),
                nums = [...dataHistoryAnnotator].sort((a, b) => a.timeTaken - b.timeTaken);
            return dataHistoryAnnotator.length % 2 !== 0 ? nums[mid].timeTaken : (nums[mid - 1].timeTaken + nums[mid].timeTaken) / 2;
        },
        [dataHistoryAnnotator],
    );

    const medianTimeTakenVal = useMemo(
        () => {
            if (dataHistoryValidator.length == 0) { return 0; }
            const mid = Math.floor(dataHistoryValidator.length / 2),
                nums = [...dataHistoryValidator].sort((a, b) => a.timeTaken - b.timeTaken);
            return dataHistoryValidator.length % 2 !== 0 ? nums[mid].timeTaken : (nums[mid - 1].timeTaken + nums[mid].timeTaken) / 2;
        },
        [dataHistoryValidator],
    );

    const sumDurationAnt = useMemo(
        () => dataHistoryAnnotator.reduce((acc, curr) => acc + curr.duration, 0),
        [dataHistoryAnnotator],
    );

    const sumDurationVal = useMemo(
        () => dataHistoryValidator.reduce((acc, curr) => acc + curr.duration, 0),
        [dataHistoryValidator],
    );

    // const maxAge = useMemo(
    // () => data.reduce((acc, curr) => Math.max(acc, curr.age), 0),
    // [],
    // );

    const columnsTask = useMemo<MRT_ColumnDef<Task>[]>(
        () => [
            // {
            //   header: 'Account Status',
            //   accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
            //   id: 'isActive',
            //   filterVariant: 'checkbox',
            //   Cell: ({ cell }) =>
            //     cell.getValue() === 'true' ? 'Active' : 'Inactive',
            //   size: 220,
            // },
            {
                accessorKey: 'speech_id',
                header: 'SPEECH ID',
                // filterVariant: 'text', // default
                enableColumnFilter: false,
                // enableClickToCopy: true,
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    startIcon: <ContentCopyIcon />,
                    sx: { justifyContent: 'flex-start' },
                },
                Cell: ({ cell, row }: any) => {
                    // console.log(cell, row)
                    return (
                        <Box
                            sx={{
                                cursor: 'pointer'
                            }}
                        >
                            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                            <span>{cell.getValue()}</span>
                        </Box>
                    )
                },
            },
            // {
            //     accessorKey: 'duration',
            //     header: 'DURATION',
            //     filterVariant: 'range',
            //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
            //     filterFn: 'greaterThan', // use betweenInclusive instead of between
            //     // enableResizing: false, //disable resizing for this column
            //     // enablePinning: false,
            //     // size: 100,
            //     // muiTableBodyCellProps: {
            //     //     sx: { textAlign: 'center' }
            //     // },
            //     Cell: ({ cell, row }: any) => (
            //         <Box
            //             sx={{
            //                 display: 'flex',
            //                 alignItems: 'center',
            //                 // justifyContent: 'center',
            //                 gap: '1rem',
            //             }}
            //         >

            //             {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //             <span>{formatHourMinSec(cell.getValue())}</span>
            //         </Box>
            //     ),
            // },
            // {
            //     accessorKey: 'size',
            //     header: 'SIZE (Mb)',
            //     filterVariant: 'range',
            //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
            //     filterFn: 'lessThan', // use betweenInclusive instead of between
            //     // enableResizing: false, //disable resizing for this column
            //     // enablePinning: false,
            //     // size: 100,
            //     // muiTableBodyCellProps: {
            //     //     sx: { textAlign: 'center' }
            //     // },
            // },
            {
                accessorFn: (row) => levels[row.level],
                accessorKey: 'level',
                header: 'LEVEL',
                enableColumnFilter: userInfo.current && privilegedValidators.includes(userInfo.current.userId) ? true : false,  // userInfo.current.role == "validator"
                filterVariant: 'multi-select',
                filterSelectOptions: levels,

                // muiTableHeadCellProps: {
                //   sx: { textAlign: 'center' }
                // },
                // muiTableBodyCellProps: {
                //     sx: { textAlign: 'center' }
                // },
                // Header: ({ column }) => <em>{column.columnDef.header}</em>,
                // Cell: ({ cell, row }: any) => (
                //     <Box
                //         sx={{
                //             display: 'flex',
                //             alignItems: 'center',
                //             gap: '1rem',
                //         }}
                //     >
                //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                //         <span>{cell.getValue()}</span>
                //     </Box>
                // ),
            },
            {
                accessorKey: 'distribution',
                header: 'DISTRIBUTION',
                enableColumnFilter: false,
                enableHiding: false,
                // filterVariant: 'multi-select',
                // filterSelectOptions: distributions,
            },
            {
                accessorKey: 'distribution_source',
                header: 'DISTRIBUTION_SOURCE',
                enableColumnFilter: false,
                enableHiding: false,
                // filterVariant: 'multi-select',
                // filterSelectOptions: distribution_sources,
            },
            {
                accessorKey: 'no_of_times_annotated',
                header: 'ANNOTATED',
                enableColumnFilter: false,
                enableHiding: false,
                // filterVariant: 'multi-select',
                // filterSelectOptions: levels,
                muiTableBodyCellProps: {
                    sx: { textAlign: 'center' }
                },
                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Badge badgeContent={cell.getValue()} color="success" showZero>
                            <PersonIcon color="action" />
                        </Badge>
                    </Box>
                ),
            },
        ],
        [],
    );

    const columnsHistoryAnnotator = useMemo<MRT_ColumnDef<TaskHistoryAnnotator>[]>(
        () => [
            // {
            //   header: 'Account Status',
            //   accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
            //   id: 'isActive',
            //   filterVariant: 'checkbox',
            //   Cell: ({ cell }) =>
            //     cell.getValue() === 'true' ? 'Active' : 'Inactive',
            //   size: 220,
            // },
            {
                accessorKey: 'speech_id',
                header: 'SPEECH ID',
                // filterVariant: 'text', // default
                // enableClickToCopy: true,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    startIcon: <ContentCopyIcon />,
                    sx: { justifyContent: 'flex-start' },
                },
                Cell: ({ cell, row }: any) => (
                    <Box sx={{ cursor: 'pointer' }}>
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <Tooltip
                            //   open={open}
                            placement="bottom-start"
                            arrow={true}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        // color: "purple",
                                        backgroundColor: "rgba(53, 53, 53, 1)",
                                        minWidth: 250,
                                        maxWidth: 500,
                                        maxHeight: 300,
                                        overflowX: 'auto',
                                        overflowY: 'auto'
                                        // fontSize: "2em"
                                    }
                                }
                            }}

                            //   {...rest}
                            title={
                                dataHistoryAnnotator && dataHistoryAnnotator.length > 0 && dataHistoryAnnotator.filter((item: any) =>
                                    item.speech_id == cell.getValue()
                                )[0].verified == "YES" ? (
                                    <Stack>
                                        <TableContainer component={Paper}>
                                            <Table stickyHeader size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>REGION ID</TableCell>
                                                        <TableCell align="center">DEDUCTED DURATION</TableCell>
                                                        <TableCell align="center">REASON</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {verificationStatsDict.current[cell.getValue()].slice(1,).map((row: any, index: any) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.segment_id}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ backgroundColor: 'rgba(255, 0, 0, 0.0625)' }}>
                                                                {row.deducted_duration}
                                                            </TableCell>
                                                            <TableCell align="justify">{row.reason}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box>
                                            {verificationStatsDict.current[cell.getValue()][0].verdict}
                                        </Box>
                                    </Stack>
                                ) : ''
                            }
                        >
                            <a href='https://google.com' target='_blank'>{cell.getValue().split('|')[0]}</a>
                        </Tooltip>
                    </Box>
                ),
            },
            // {
            //     accessorKey: 'duration',
            //     header: 'DURATION',
            //     filterVariant: 'range',
            //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
            //     filterFn: 'betweenInclusive', // use betweenInclusive instead of between
            //     // enableResizing: false, //disable resizing for this column
            //     // enablePinning: false,
            //     // size: 100,
            //     // muiTableBodyCellProps: {
            //     //     sx: { textAlign: 'center' }
            //     // },
            //     Cell: ({ cell, row }: any) => (
            //         <Box
            //             sx={{
            //                 display: 'flex',
            //                 alignItems: 'center',
            //                 // justifyContent: 'center',
            //                 gap: '1rem',
            //             }}
            //         >

            //             {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //             <span>{formatHourMinSec(cell.getValue())}</span>
            //         </Box>
            //     ),

            //     aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
            //     //required to render an aggregated cell
            //     AggregatedCell: ({ cell, table }) => (
            //         <>
            //             <FunctionsRoundedIcon />
            //             {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            //             <Box
            //                 sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            //             >
            //                 {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
            //                 {formatHourMinSec(cell.getValue<number>())}
            //             </Box>
            //         </>
            //     ),
            //     Footer: () => (
            //         <Stack>
            //             TOTAL DURATION:
            //             <Box color="warning.main">{formatHourMinSec(sumDuration)}</Box>
            //         </Stack>
            //     ),
            // },
            {
                accessorKey: 'timeTaken',
                header: 'TIME TAKEN',
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,
                // enableResizing: false, //disable resizing for this column
                // enablePinning: false,
                // size: 100,
                // muiTableBodyCellProps: {
                //     sx: { textAlign: 'center' }
                // },
                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),

                // aggregationFn: 'median', //show the max age in the group (lots of pre-built aggregationFns to choose from)
                // //required to render an aggregated cell
                // AggregatedCell: ({ cell, table }) => (
                //     <>
                //         <FunctionsRoundedIcon />
                //         {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
                //         <Box
                //             sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
                //         >
                //             {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
                //             {formatHourMinSec(cell.getValue<number>())}
                //         </Box>
                //     </>
                // ),
                Footer: () => (
                    <Stack>
                        MEDIAN TIME TAKEN:
                        <Box color="warning.main">{formatHourMinSec(medianTimeTakenAnt)}</Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'annotatedDuration',
                header: "ANNOTATED DURATION (s)",
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,

                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),

                // aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
                // //required to render an aggregated cell
                // AggregatedCell: ({ cell, table }) => (
                //     <>
                //         <FunctionsRoundedIcon />
                //         {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
                //         <Box
                //             sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
                //         >
                //             {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
                //             {formatHourMinSec(cell.getValue<number>())}
                //         </Box>
                //     </>
                // ),
                Footer: () => (
                    <Stack>
                        TOTAL ANNOTATED:
                        <Box color="warning.main">{formatHourMinSec(sumAnnotatedDuration)}</Box>
                    </Stack>
                ),
                // enableResizing: false, //disable resizing for this column
                // enablePinning: false,
                // size: 100,
                // muiTableBodyCellProps: {
                //     sx: { textAlign: 'center' }
                // },
                // Cell: ({ cell, row }: any) => (
                //     <Box
                //         sx={{
                //             display: 'flex',
                //             alignItems: 'center',
                //             // justifyContent: 'center',
                //             gap: '1rem',
                //         }}
                //     >

                //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                //         <span>{`${("0" + Math.floor(cell.getValue() / 60)).slice(-2)} min ${("0" + Math.round(cell.getValue() % 60)).slice(-2)} sec`}</span>
                //     </Box>
                // ),
            },
            {
                accessorKey: 'validated',
                header: 'VALIDATED',
                // filterVariant: 'select',
                // filterSelectOptions: ["YES", "NO"],
                enableColumnFilter: false,
                Footer: () => (
                    <Stack>
                        TOTAL VALIDATED:
                        <Box color="warning.main">{formatHourMinSec(sumValidatedDurationAnt)}</Box>
                    </Stack>
                ),
            },
            // {
            //     accessorKey: 'validatedDuration',
            //     header: 'VALIDATED DURATION (s)',
            //     // filterVariant: 'range',
            //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
            //     // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
            //     enableColumnFilter: false,
            //     // enableHiding: userInfo.current.role == "validator" ? false : true,

            //     Cell: ({ cell, row }: any) => (
            //         <Box
            //             sx={{
            //                 display: 'flex',
            //                 alignItems: 'center',
            //                 // justifyContent: 'center',
            //                 gap: '1rem',
            //             }}
            //         >

            //             {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //             <span>{formatHourMinSec(cell.getValue())}</span>
            //         </Box>
            //     ),

            //     aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
            //     //required to render an aggregated cell
            //     AggregatedCell: ({ cell, table }) => (
            //         <>
            //             <FunctionsRoundedIcon />
            //             {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            //             <Box
            //                 sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            //             >
            //                 {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
            //                 {formatHourMinSec(cell.getValue<number>())}
            //             </Box>
            //         </>
            //     ),
            //     Footer: () => (
            //         <Stack>
            //             TOTAL VALIDATED:
            //             <Box color="warning.main">{formatHourMinSec(sumValidatedDuration)}</Box>
            //         </Stack>
            //     ),
            // },
            // {
            //     accessorKey: 'overlappedDuration',
            //     header: 'OVERLAPPED DURATION (s)',
            //     // filterVariant: 'range',
            //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
            //     // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
            //     enableColumnFilter: false,
            //     // enableHiding: userInfo.current.role == "validator" ? false : true,

            //     Cell: ({ cell, row }: any) => (
            //         <Box
            //             sx={{
            //                 display: 'flex',
            //                 alignItems: 'center',
            //                 // justifyContent: 'center',
            //                 gap: '1rem',
            //             }}
            //         >

            //             {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //             <span>{formatHourMinSec(cell.getValue())}</span>
            //         </Box>
            //     ),
            //     Footer: () => (
            //         <Stack>
            //             TOTAL OVERLAPPED:
            //             <Box color="warning.main">{formatHourMinSec(sumOverlappedDurationAnt)}</Box>
            //         </Stack>
            //     ),
            // },
            {
                accessorKey: 'verified',
                header: 'VERIFIED',
                // filterVariant: 'select',
                // filterSelectOptions: ["YES", "NO"],
                enableColumnFilter: false,
                Footer: () => (
                    <Stack>
                        TOTAL VERIFIED:
                        <Box color="warning.main">{formatHourMinSec(sumVerifiedDurationAnt)}</Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'deductedDuration',
                header: 'DEDUCTED DURATION (s)',
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,
                // enableHiding: userInfo.current.role == "validator" ? false : true,

                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),
                Footer: () => (
                    <Stack>
                        TOTAL DEDUCTED:
                        <Box color="error.main">{formatHourMinSec(sumDeductedDurationAnt)}</Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'acceptedDuration',
                header: 'ACCEPTED DURATION (s)',
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,

                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),
                Footer: () => (
                    <Stack>
                        TOTAL ACCEPTED:
                        <Box color="success.main">{formatHourMinSec(sumAcceptedDurationAnt)}</Box>
                    </Stack>
                ),
            },
            // {
            //     accessorKey: 'billingStatus',
            //     header: 'BILLING STATUS',
            //     // filterVariant: 'multi-select',
            //     // filterSelectOptions: billing_status_list,
            //     enableColumnFilter: false,

            //     // muiTableHeadCellProps: {
            //     //   sx: { textAlign: 'center' }
            //     // },
            //     muiTableBodyCellProps: {
            //         sx: {
            //             // display: 'flex',
            //             // alignItems: 'center',
            //             textAlign: 'center',
            //             // gap: '1rem',
            //             backgroundColor: 'rgba(255, 0, 0, 0.1)'
            //         }
            //     },
            //     // Header: ({ column }) => <em>{column.columnDef.header}</em>,
            //     // Cell: ({ cell, row }: any) => (
            //     //     <Box
            //     //         sx={{
            //     //             // display: 'flex',
            //     //             // alignItems: 'center',
            //     //             textAlign: 'center',
            //     //             // gap: '1rem',
            //     //             backgroundColor: cell.getValue() == 'UNPAID' ? 'rgba(255, 0, 0, 0.1)' : cell.getValue() == 'PROCESSING' ? 'rgba(0, 0, 255, 0.1)' : 'rgba(0, 255, 0, 0.1)'
            //     //         }}
            //     //     >
            //     //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //     //         {cell.getValue()}
            //     //     </Box>
            //     // ),
            // },
            {
                // accessorFn: (row) => new Date(row.submittedOn), //convert to Date for sorting and filtering
                accessorKey: 'submittedOn',
                header: 'SUBMITTED ON',

                // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
                // Cell: ({ cell }) => new Date(cell.getValue<Date>()?.getTime() - (cell.getValue<Date>()?.getTimezoneOffset() * 60000)).toISOString().slice(0, -5), // render Date as a string // cell.getValue<Date>()?.toISOString()

                // sortingFn: 'datetime',
                enableColumnFilter: false,
                // filterFn: 'lessThanOrEqualTo',

                //Custom Date Picker Filter from @mui/x-date-pickers
                // Filter: ({ column }) => (
                //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                //         <DatePicker
                //             onChange={(newValue) => {
                //                 column.setFilterValue(newValue);
                //             }}
                //             renderInput={(params) => (
                //                 <TextField
                //                     {...params}
                //                     helperText={'Filter Mode: Less Than'}
                //                     sx={{ minWidth: '120px' }}
                //                     variant="standard"
                //                 />
                //             )}
                //             value={column.getFilterValue()}
                //         />
                //     </LocalizationProvider>
                // ),
            },
            {
                accessorKey: 'level',
                header: 'LEVEL',
                enableColumnFilter: false,
                // filterVariant: 'multi-select',
                // filterSelectOptions: levels,

                // muiTableHeadCellProps: {
                //   sx: { textAlign: 'center' }
                // },
                // muiTableBodyCellProps: {
                //     sx: { textAlign: 'center' }
                // },
                // Header: ({ column }) => <em>{column.columnDef.header}</em>,
                // Cell: ({ cell, row }: any) => (
                //     <Box
                //         sx={{
                //             display: 'flex',
                //             alignItems: 'center',
                //             gap: '1rem',
                //         }}
                //     >
                //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                //         <span>{cell.getValue()}</span>
                //     </Box>
                // ),
            },
        ],
        [sumAnnotatedDuration, sumValidatedDurationAnt, sumVerifiedDurationAnt, sumDeductedDurationAnt, sumAcceptedDurationAnt, medianTimeTakenAnt, sumDurationAnt],
    );

    const columnsHistoryValidator = useMemo<MRT_ColumnDef<TaskHistoryValidator>[]>(
        () => [
            // {
            //   header: 'Account Status',
            //   accessorFn: (originalRow) => (originalRow.isActive ? 'true' : 'false'), //must be strings
            //   id: 'isActive',
            //   filterVariant: 'checkbox',
            //   Cell: ({ cell }) =>
            //     cell.getValue() === 'true' ? 'Active' : 'Inactive',
            //   size: 220,
            // },
            {
                accessorKey: 'speech_id',
                header: 'SPEECH ID',
                // filterVariant: 'text', // default
                // enableClickToCopy: true,
                enableColumnFilter: false,
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    startIcon: <ContentCopyIcon />,
                    sx: { justifyContent: 'flex-start' },
                },
                Cell: ({ cell, row }: any) => (
                    <Box sx={{ cursor: 'pointer' }}>
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <Tooltip
                            //   open={open}
                            placement="bottom-start"
                            arrow={true}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        // color: "purple",
                                        backgroundColor: "rgba(53, 53, 53, 1)",
                                        minWidth: 250,
                                        maxWidth: 500,
                                        maxHeight: 300,
                                        overflowX: 'auto',
                                        overflowY: 'auto'
                                        // fontSize: "2em"
                                    }
                                }
                            }}

                            //   {...rest}
                            title={
                                dataHistoryValidator && dataHistoryValidator.length > 0 && dataHistoryValidator.filter((item: any) =>
                                    item.speech_id == cell.getValue()
                                )[0].verified == "YES" ? (
                                    <Stack>
                                        <TableContainer component={Paper}>
                                            <Table stickyHeader size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>REGION ID</TableCell>
                                                        <TableCell align="center">DEDUCTED DURATION</TableCell>
                                                        <TableCell align="center">REASON</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {verificationStatsDict.current[cell.getValue()].slice(1,).map((row: any, index: any) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.segment_id}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ backgroundColor: 'rgba(255, 0, 0, 0.0625)' }}>
                                                                {row.deducted_duration}
                                                            </TableCell>
                                                            <TableCell align="justify">{row.reason}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box>
                                            {verificationStatsDict.current[cell.getValue()][0].verdict}
                                        </Box>
                                    </Stack>
                                ) : ''
                            }
                        >
                            <a href='https://google.com' target='_blank'>{cell.getValue().split('|')[0]}</a>
                        </Tooltip>
                    </Box>
                ),
            },
            // {
            //     accessorKey: 'duration',
            //     header: 'DURATION',
            //     filterVariant: 'range',
            //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
            //     filterFn: 'betweenInclusive', // use betweenInclusive instead of between
            //     // enableResizing: false, //disable resizing for this column
            //     // enablePinning: false,
            //     // size: 100,
            //     // muiTableBodyCellProps: {
            //     //     sx: { textAlign: 'center' }
            //     // },
            //     Cell: ({ cell, row }: any) => (
            //         <Box
            //             sx={{
            //                 display: 'flex',
            //                 alignItems: 'center',
            //                 // justifyContent: 'center',
            //                 gap: '1rem',
            //             }}
            //         >

            //             {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //             <span>{formatHourMinSec(cell.getValue())}</span>
            //         </Box>
            //     ),

            //     aggregationFn: 'sum', //show the max age in the group (lots of pre-built aggregationFns to choose from)
            //     //required to render an aggregated cell
            //     AggregatedCell: ({ cell, table }) => (
            //         <>
            //             <FunctionsRoundedIcon />
            //             {/* {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '} */}
            //             <Box
            //                 sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            //             >
            //                 {/* {formatHourMinSec(parseFloat(cell.getValue<number>().toFixed(3)))} */}
            //                 {formatHourMinSec(cell.getValue<number>())}
            //             </Box>
            //         </>
            //     ),
            //     Footer: () => (
            //         <Stack>
            //             TOTAL DURATION:
            //             <Box color="warning.main">{formatHourMinSec(sumDuration)}</Box>
            //         </Stack>
            //     ),
            // },
            {
                accessorKey: 'timeTaken',
                header: 'TIME TAKEN',
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,
                // enableResizing: false, //disable resizing for this column
                // enablePinning: false,
                // size: 100,
                // muiTableBodyCellProps: {
                //     sx: { textAlign: 'center' }
                // },
                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),
                Footer: () => (
                    <Stack>
                        MEDIAN TIME TAKEN:
                        <Box color="warning.main">{formatHourMinSec(medianTimeTakenVal)}</Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'validatedDuration',
                header: "VALIDATED DURATION (s)",
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,

                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),
                Footer: () => (
                    <Stack>
                        TOTAL VALIDATED:
                        <Box color="warning.main">{formatHourMinSec(sumValidatedDuration)}</Box>
                    </Stack>
                )
            },
            // {
            //     accessorKey: 'overlappedDuration',
            //     header: 'OVERLAPPED DURATION (s)',
            //     // filterVariant: 'range',
            //     // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
            //     // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
            //     enableColumnFilter: false,
            //     // enableHiding: userInfo.current.role == "validator" ? false : true,

            //     Cell: ({ cell, row }: any) => (
            //         <Box
            //             sx={{
            //                 display: 'flex',
            //                 alignItems: 'center',
            //                 // justifyContent: 'center',
            //                 gap: '1rem',
            //             }}
            //         >

            //             {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //             <span>{formatHourMinSec(cell.getValue())}</span>
            //         </Box>
            //     ),
            //     Footer: () => (
            //         <Stack>
            //             TOTAL OVERLAPPED:
            //             <Box color="warning.main">{formatHourMinSec(sumOverlappedDurationVal)}</Box>
            //         </Stack>
            //     ),
            // },
            {
                accessorKey: 'verified',
                header: 'VERIFIED',
                // filterVariant: 'select',
                // filterSelectOptions: ["YES", "NO"],
                enableColumnFilter: false,
                Footer: () => (
                    <Stack>
                        TOTAL VERIFIED:
                        <Box color="warning.main">{formatHourMinSec(sumVerifiedDurationVal)}</Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'deductedDuration',
                header: 'DEDUCTED DURATION (s)',
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,
                // enableHiding: userInfo.current.role == "validator" ? false : true,

                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),
                Footer: () => (
                    <Stack>
                        TOTAL DEDUCTED:
                        <Box color="error.main">{formatHourMinSec(sumDeductedDurationVal)}</Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'acceptedDuration',
                header: 'ACCEPTED DURATION (s)',
                // filterVariant: 'range',
                // columnFilterModeOptions: ['between', 'lessThan', 'greaterThan', 'betweenInclusive'],
                // filterFn: 'betweenInclusive', // use betweenInclusive instead of between
                enableColumnFilter: false,

                Cell: ({ cell, row }: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >

                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{formatHourMinSec(cell.getValue())}</span>
                    </Box>
                ),
                Footer: () => (
                    <Stack>
                        TOTAL ACCEPTED:
                        <Box color="success.main">{formatHourMinSec(sumAcceptedDurationVal)}</Box>
                    </Stack>
                ),
            },
            // {
            //     accessorKey: 'billingStatus',
            //     header: 'BILLING STATUS',
            //     // filterVariant: 'multi-select',
            //     // filterSelectOptions: billing_status_list,
            //     enableColumnFilter: false,

            //     // muiTableHeadCellProps: {
            //     //   sx: { textAlign: 'center' }
            //     // },
            //     muiTableBodyCellProps: {
            //         sx: {
            //             // display: 'flex',
            //             // alignItems: 'center',
            //             textAlign: 'center',
            //             // gap: '1rem',
            //             backgroundColor: 'rgba(255, 0, 0, 0.1)'
            //         }
            //     },
            //     // Header: ({ column }) => <em>{column.columnDef.header}</em>,
            //     // Cell: ({ cell, row }: any) => (
            //     //     <Box
            //     //         sx={{
            //     //             // display: 'flex',
            //     //             // alignItems: 'center',
            //     //             textAlign: 'center',
            //     //             // gap: '1rem',
            //     //             backgroundColor: cell.getValue() == 'UNPAID' ? 'rgba(255, 0, 0, 0.1)' : cell.getValue() == 'PROCESSING' ? 'rgba(0, 0, 255, 0.1)' : 'rgba(0, 255, 0, 0.1)'
            //     //         }}
            //     //     >
            //     //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            //     //         {cell.getValue()}
            //     //     </Box>
            //     // ),
            // },
            {
                // accessorFn: (row) => new Date(row.submittedOn), //convert to Date for sorting and filtering
                accessorKey: 'submittedOn',
                header: 'SUBMITTED ON',

                // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
                // Cell: ({ cell }) => new Date(cell.getValue<Date>()?.getTime() - (cell.getValue<Date>()?.getTimezoneOffset() * 60000)).toISOString().slice(0, -5), // render Date as a string // cell.getValue<Date>()?.toISOString()

                // sortingFn: 'datetime',
                enableColumnFilter: false
            },
            {
                accessorKey: 'level',
                header: 'LEVEL',
                enableColumnFilter: false,
                // filterVariant: 'multi-select',
                // filterSelectOptions: levels,

                // muiTableHeadCellProps: {
                //   sx: { textAlign: 'center' }
                // },
                // muiTableBodyCellProps: {
                //     sx: { textAlign: 'center' }
                // },
                // Header: ({ column }) => <em>{column.columnDef.header}</em>,
                // Cell: ({ cell, row }: any) => (
                //     <Box
                //         sx={{
                //             display: 'flex',
                //             alignItems: 'center',
                //             gap: '1rem',
                //         }}
                //     >
                //         {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                //         <span>{cell.getValue()}</span>
                //     </Box>
                // ),
            },
        ],
        [sumValidatedDuration, sumVerifiedDurationVal, sumDeductedDurationVal, sumAcceptedDurationVal, medianTimeTakenVal, sumDurationVal],
    );

    let history = useHistory();
    const [userId, setUserId]: any = useState(null);

    // const [response, setResponse]: any = useState([]);

    const [loading, setLoading]: any = useState(true);

    // const [tabValue, setTabValue] = useState(localStorage.getItem('tabValue') ? parseInt(localStorage.getItem('tabValue')) : 0);

    const [showCurrentTime, setShowCurrentTime] = useState(localStorage.getItem('showCurrentTime') ? JSON.parse(localStorage.getItem('showCurrentTime')) : true)
    const [showCurrentTimePV, setShowCurrentTimePV] = useState(localStorage.getItem('showCurrentTimePV') ? JSON.parse(localStorage.getItem('showCurrentTimePV')) : true)

    const MIN_DATE = new Date('2023-01-01T00:00:00.000000');
    const MAX_DATE = new Date();

    const [startDateTimePV, setStartDateTimePV]: any = React.useState<Dayjs | null>(localStorage.getItem('startDateTimePV') ? dayjs(localStorage.getItem('startDateTimePV')) : dayjs(MIN_DATE));
    const [endDateTimePV, setEndDateTimePV]: any = React.useState<Dayjs | null>((!showCurrentTime) && localStorage.getItem('endDateTimePV') ? dayjs(localStorage.getItem('endDateTimePV')) : dayjs(MAX_DATE));

    const [startDateTime, setStartDateTime]: any = React.useState<Dayjs | null>(localStorage.getItem('startDateTime') ? dayjs(localStorage.getItem('startDateTime')) : dayjs(MIN_DATE));
    const [endDateTime, setEndDateTime]: any = React.useState<Dayjs | null>((!showCurrentTime) && localStorage.getItem('endDateTime') ? dayjs(localStorage.getItem('endDateTime')) : dayjs(MAX_DATE));
    // const [tabs, setTabs] = useState(['STT', 'TTS', 'ANNOTATION', 'VALIDATION'])
    const [historyMode, setHistoryMode] = useState(false)
    // const { speak, voices } = useSpeechSynthesis();

    // const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    //     console.log("HANDLE TAB CLICK: ", newValue)
    //     setTabValue(newValue);
    // };

    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState<'light' | 'dark'>(localStorage.getItem('darkMode') == 'light' ? 'light' : 'dark');
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                localStorage.setItem('darkMode', mode === 'light' ? 'dark' : 'light')
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                },
            }),
        [mode],
    );

    function MyHeader() {
        const theme = useTheme();
        const colorMode = useContext(ColorModeContext);
        // const color = red[500];

        const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
        const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

        const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElUser(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
            setAnchorElNav(null);
        };

        const handleCloseUserMenu = () => {
            setAnchorElUser(null);
        };

        // return (
        //     <div className="mb-5" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: '0px solid red' }}>
        //         <div style={{ width: 'fit-content' }}>
        //             <Button variant="contained" color="primary" endIcon={<HomeIcon />} onClick={() => window.location.replace(window.location.origin + `/`)}>
        //                 HOME
        //             </Button>
        //         </div>
        //         <Stack direction="row" spacing={2} alignItems="center">
        //             <Typography variant="h5" sx={{ fontWeight: '600' }}>REGISTRATION</Typography>
        //             {/* {theme.palette.mode} mode */}
        //             <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        //                 {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
        //             </IconButton>
        //         </Stack>
        //         <div style={{ width: 'fit-content' }}>
        //             <Button variant="contained" color="success" endIcon={<LoginIcon />} onClick={() => window.location.replace(window.location.origin + `/login`)}>
        //                 LOGIN
        //             </Button>
        //         </div>
        //     </div>
        // );
        return (
            <AppBar position="sticky" sx={{ backgroundColor: '#212121' }}>
                <Container maxWidth="xl">
                    <Toolbar >
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <AssignmentTurnedInIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href='/annotation-task'
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            {historyMode ? "HISTORY" : "TASKS"}
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={(event: any) => {
                                        handleCloseNavMenu();
                                        let pageName = event.target.innerText;
                                        // if (pageName.toLowerCase() == 'registration') {
                                        //     window.location.replace(window.location.origin + `/registration`)
                                        // } else if (pageName.toLowerCase() == 'home') {
                                        //     window.location.replace(window.location.origin + `/`)
                                        // }
                                        if (pageName.toLowerCase() == 'logout') {
                                            onLogout();
                                        }
                                    }}>
                                        <Typography textAlign="center">{page}</Typography>
                                        {/* {page.toLowerCase()=='registration' ? <LoginIcon /> : <HomeIcon />} */}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <AssignmentTurnedInIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href='/annotation-task'
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            {historyMode ? "HISTORY" : "TASKS"}
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    variant="contained"
                                    color="success"
                                    onClick={(event: any) => {
                                        handleCloseNavMenu();
                                        let targetButton = event.target;
                                        while (targetButton.nodeName != 'BUTTON') {
                                            targetButton = targetButton.parentNode;
                                        }
                                        let pageName = targetButton.innerText;
                                        // if (pageName.toLowerCase() == 'registration') {
                                        //     window.location.replace(window.location.origin + `/registration`)
                                        // } else if (pageName.toLowerCase() == 'home') {
                                        //     window.location.replace(window.location.origin + `/`)
                                        // }
                                        if (pageName.toLowerCase() == 'logout') {
                                            onLogout();
                                        }
                                    }}
                                    endIcon={page.toLowerCase() == 'registration' ? <PersonAddIcon /> : page.toLowerCase() == 'home' ? <HomeIcon /> : <LogoutIcon />}
                                    sx={{ ml: 2, my: 2, color: 'white', backgroundColor: '#004d40' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            {/* <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src={activity} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu> */}
                            <IconButton sx={{ ml: 1 }} onClick={() => {
                                setHistoryMode(!historyMode)
                            }} color={historyMode ? "error" : "inherit"}>
                                <HistoryIcon />
                            </IconButton>
                            <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                                {theme.palette.mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

    useEffect(() => {
        (async () => {
            console.log("HISTORY MODE CHANGED: ", historyMode);

            if (historyMode) {
                // if (userInfo.current.roles.includes(1) || userInfo.current.roles.includes(3) || userInfo.current.roles.includes(5) || userInfo.current.roles.includes(7)) {
                //     newValue = 0;
                // } else {
                //     newValue = 1;
                // }
                // if (newValue == tabValue) {
                //     await loadDataHistory();
                // }
                // setTabValue(newValue);
                // // setTabs(['ANNOTATION', 'VALIDATION']);
                await loadDataHistory();
            } else {
                // newValue = localStorage.getItem('tabValue') ? parseInt(localStorage.getItem('tabValue')) : 0;
                // if (newValue == tabValue) {
                //     await loadDataTask();
                // }
                // setTabValue(newValue);
                // // setTabs(['STT', 'TTS']);
                if (!firstRender.current) {
                    await loadDataTask();
                }
            }
        })()
    }, [historyMode])

    useEffect(() => {
        // console.log("FIRST RENDER: ", firstRender.current);
        // localStorage.removeItem('globalFilter');
        // localStorage.removeItem('globalFilterHistory');

        // if (firstRender.current) {
        let user = decodeToken()
        console.log('LoggedIn-UserInfo', user);
        if (user) {
            userInfo.current = user;
            setUserId(user.userId);
        } else {
            window.location.replace(window.location.origin + `/login`)
        }

        (async () => {
            let unfinished: any = await hasUnfinishedTask();
            if ('error' in unfinished) {
                return;
            } else {
                userInfo.current['role'] = unfinished['role']
                userInfo.current['tts'] = unfinished['tts']

                if (!unfinished["saved"]) {
                    await loadDataTask();
                } else {
                    localStorage.setItem("speech_id", unfinished["speech_id"])
                    // history.push(`/annotation/`);
                    // history.go(0);
                    window.location.replace(window.location.origin + `/annotation`)
                }
            }
        })();
        firstRender.current = false;
        // }
    }, [])

    useEffect(() => {
        console.log("COLUMN VISIBILITY CHANGED: ", columnVisibility);
        if (!firstRender.current) {
            localStorage.setItem('columnVisibility', JSON.stringify(columnVisibility));
        }
    }, [columnVisibility])

    useEffect(() => {
        console.log("COLUMN FILTER CHANGED: ", columnFilters);
        if (!firstRender.current) {
            localStorage.setItem('columnFilters', JSON.stringify(columnFilters));
        }
    }, [columnFilters])

    useEffect(() => {
        console.log("GLOBAL FILTER CHANGED: ", globalFilter);
        // if(!firstRender.current){
        //     if(globalFilter){
        //         localStorage.setItem('globalFilter', JSON.stringify(globalFilter));
        //     } else{
        //         localStorage.removeItem('globalFilter');
        //     }
        // }
    }, [globalFilter])

    useEffect(() => {
        console.log("SORTING CHANGED: ", sorting);
        if (!firstRender.current) {
            localStorage.setItem('sorting', JSON.stringify(sorting));
        }
    }, [sorting])

    useEffect(() => {
        console.log("PAGINATION CHANGED !!! ", pagination);
        if (!firstRender.current) {
            localStorage.setItem('pagination', JSON.stringify(pagination));
        }
    }, [pagination])


    useEffect(() => {
        console.log("COLUMN VISIBILITY HISTORY CHANGED: ", columnVisibilityHistory);
        localStorage.setItem('columnVisibilityHistory', JSON.stringify(columnVisibilityHistory));
    }, [columnVisibilityHistory])

    useEffect(() => {
        console.log("COLUMN FILTER HISTORY CHANGED: ", columnFiltersHistory);
        if (!firstRender.current) {
            localStorage.setItem('columnFiltersHistory', JSON.stringify(columnFiltersHistory));
        }
    }, [columnFiltersHistory])

    useEffect(() => {
        console.log("GLOBAL FILTER HISTORY CHANGED: ", globalFilterHistory);
        // localStorage.setItem('globalFilterHistory', JSON.stringify(globalFilterHistory));
    }, [globalFilterHistory])

    useEffect(() => {
        console.log("SORTING HISTORY CHANGED: ", sortingHistory);
        if (!firstRender.current) {
            localStorage.setItem('sortingHistory', JSON.stringify(sortingHistory));
        }
    }, [sortingHistory])

    useEffect(() => {
        console.log("PAGINATION HISTORY CHANGED !!! ", paginationHistory);
        if (!firstRender.current) {
            localStorage.setItem('paginationHistory', JSON.stringify(paginationHistory));
        }
    }, [paginationHistory])

    useEffect(() => {
        console.log("SHOW CURRENT TIME PV CHANGED !!! ", showCurrentTimePV);
        if (!firstRender.current) {
            localStorage.setItem('showCurrentTimePV', JSON.stringify(showCurrentTimePV));
        }
        if (showCurrentTimePV) {
            setEndDateTimePV(dayjs(MAX_DATE))
        } else {
            setEndDateTimePV(localStorage.getItem('endDateTimePV') ? dayjs(localStorage.getItem('endDateTimePV')) : dayjs(MAX_DATE))
        }
    }, [showCurrentTimePV])

    useEffect(() => {
        console.log("SHOW CURRENT TIME CHANGED !!! ", showCurrentTime);
        if (!firstRender.current) {
            localStorage.setItem('showCurrentTime', JSON.stringify(showCurrentTime));
        }
        if (showCurrentTime) {
            setEndDateTime(dayjs(MAX_DATE))
        } else {
            setEndDateTime(localStorage.getItem('endDateTime') ? dayjs(localStorage.getItem('endDateTime')) : dayjs(MAX_DATE))
        }
    }, [showCurrentTime])

    useEffect(() => {
        console.log("Target Annotator ID: ", targetAnnotatorId);
        if (!firstRender.current) {
            if (targetAnnotatorId) {
                localStorage.setItem('targetAnnotatorId', targetAnnotatorId);
            }
            else {
                localStorage.removeItem('targetAnnotatorId');
            }
        }
    }, [targetAnnotatorId])

    // useEffect(() => {
    //     (async () => {
    //         console.log("TAB VALUE CHANGED: ", tabValue);

    //         if (historyMode) {
    //             await loadDataHistory();
    //         } else {
    //             localStorage.setItem("tabValue", tabValue.toString());
    //             if (!firstRender.current) {
    //                 await loadDataTask();
    //             }
    //         }
    //     })()
    // }, [tabValue])

    // useEffect(() => {
    //     console.log("TABS CHANGED: ", tabs);
    // }, [tabs])

    useEffect(() => {
        // console.log("DATA TASK RESET !!! ", dataTask);
        setGlobalFilter(undefined);
        setShowGlobalFilter(false);
    }, [dataTask])

    useEffect(() => {
        console.log("DATA HISTORY ANNOTATOR RESET !!! ", dataHistoryAnnotator);
    }, [dataHistoryAnnotator])

    useEffect(() => {
        console.log("DATA HISTORY VALIDATOR RESET !!! ", dataHistoryValidator);
    }, [dataHistoryValidator])

    const hasUnfinishedTask = async () => {
        setLoading(true);
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        try {
            let requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    "annotator_id": userInfo.current.userId
                }),
                cache: "no-cache"
            };

            let response = await fetch("https://amarkantho.revesoft.com:5103/has-unfinished-work/", requestOptions)
            let status = response.status

            if (status == 200 || status == 201) {
                let result = await response.json()
                setLoading(false);
                // ToastSuccessMsg(responseBody.message)
                console.log("HAS UNFINISHED WORK: ", result);
                return result;
            } else if (status == 422) {
                // ToastFailedMsg("Field Missing !");
                setLoading(false);
                return { "error": "Field Missing !" }
            } else {
                let responseBody = await response.json()
                setLoading(false);
                ToastFailedMsg(responseBody.message)
                return { "error": responseBody.message }
            }
        }
        catch (err: any) {
            setLoading(false)
            console.log("ERROR ! ", err.message)
            ToastFailedMsg(`ERROR ! ${err.message}`)
            // window.location.replace(window.location.origin + `/`)
            return { "error": err.message }
        }
    }

    const loadDataTask = async () => {
        setLoading(false);
        // if (!dataTask.length) {
        //     setIsLoading(true);
        // } else {
        //     setIsRefetching(true);
        // }

        setIsLoading(true);

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let reqObj = "";
        let user_id = userInfo.current.userId;
        let isPrivileged = user_id && privilegedValidators.includes(user_id);
        if (!isPrivileged) {
            reqObj = JSON.stringify({
                "annotator_id": user_id,
                "column_filters": columnFilters
            })
        } else {
            // console.log(startDateTimePV, startDateTimePV['$d'], typeof startDateTimePV['$d']);
            let dateStart = new Date(startDateTimePV['$d']);
            let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
            console.log(startDateTimeISO);

            // console.log(endDateTime, endDateTime['$d'], typeof endDateTime['$d']);
            let dateEnd = new Date(endDateTimePV['$d']);
            let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
            console.log(endDateTimeISO);

            reqObj = JSON.stringify({
                "annotator_id": user_id,
                "column_filters": columnFilters,
                "global_filter": globalFilter === undefined ? '' : globalFilter,
                "target_annotator": targetAnnotatorId,
                "start_date": startDateTimeISO,
                "end_date": endDateTimeISO
            })
        }

        try {
            let requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: reqObj,
                cache: "no-cache"
            };

            let PULL_ANTS_PORT = sttEvalAnnotators.includes(userInfo.current.userId) ? 5117 : 5104;

            let res = await fetch(`https://amarkantho.revesoft.com:${PULL_ANTS_PORT}/pull-annotations/`, requestOptions)
            if (res.status === 200 || res.status === 201) {
                let datares = await res.json();
                // console.log("datares response: ", datares?.data);
                setIsLoading(false);
                setIsRefetching(false);
                setIsError(false);
                setDataTask(datares.data.map((item: any, index: any) => {
                    // console.log(item, index);

                    // let annotation_status = ''
                    // if (item.annotation_status == "0_0") {
                    //     annotation_status = "SENTENCE - MACHINE"
                    // } else if (item.annotation_status.startsWith("0")) {
                    //     annotation_status = `SENTENCE - HUMAN (${item.annotation_status.split('_')[1]})`
                    // } else if (item.annotation_status == "1_0") {
                    //     annotation_status = "PHONEME - MACHINE"
                    // } else if (item.annotation_status.startsWith("1")) {
                    //     annotation_status = `PHONEME - HUMAN (${item.annotation_status.split('_')[1]})`
                    // } else if (item.annotation_status == "2_0") {
                    //     annotation_status = "WORD - MACHINE"
                    // } else if (item.annotation_status.startsWith("2")) {
                    //     annotation_status = `WORD - HUMAN (${item.annotation_status.split('_')[1]})`
                    // } else if (item.annotation_status == "3_0") {
                    //     annotation_status = "ALIGN - MACHINE"
                    // } else if (item.annotation_status.startsWith("3")) {
                    //     annotation_status = `ALIGN - HUMAN (${item.annotation_status.split('_')[1]})`
                    // }

                    return {
                        speech_id: item.speech_id,
                        duration: item.duration,
                        size: item.size,
                        level: item.annotation_status,
                        distribution: item.distribution,
                        distribution_source: item.distribution_source,
                        no_of_times_annotated: item.no_of_times_annotated
                        // remarks: item.remark
                    }
                }));
            } else {
                let error = await res.json()
                setIsLoading(false);
                setIsRefetching(false);
                setIsError(true);
                console.log("ERROR !!! ", error.message)
            }
        }
        catch (err: any) {
            setIsLoading(false);
            setIsRefetching(false);
            setIsError(true);
            console.log("ERROR ! ", err.message)
            ToastFailedMsg(`ERROR ! ${err.message}`)
            // // window.location.replace(window.location.origin + `/`)
            // return { "error": err.message }
        }
    }

    const loadDataHistory = async () => {
        setLoading(false);
        // if (!dataHistory.length) {
        //     setIsLoading(true);
        // } else {
        //     setIsRefetching(true);
        // }

        setIsLoading(true);

        console.log(startDateTime, startDateTime['$d'], typeof startDateTime['$d']);
        let dateStart = new Date(startDateTime['$d']);
        let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
        console.log(startDateTimeISO);

        console.log(endDateTime, endDateTime['$d'], typeof endDateTime['$d']);
        let dateEnd = new Date(endDateTime['$d']);
        let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
        console.log(endDateTimeISO);

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "annotator_id": userInfo.current.userId,
                "column_filters": columnFiltersHistory,
                "start_date": startDateTimeISO,
                "end_date": endDateTimeISO
            }),
            cache: "no-cache"
        };

        let res = await fetch("https://amarkantho.revesoft.com:5113/pull-prev-works/", requestOptions)

        if (res.status === 200 || res.status === 201) {
            let datares = await res.json();
            console.log("datares response: ", datares);

            setIsLoading(false);
            setIsRefetching(false);
            setIsError(false);

            let targetArray = datares['history'].map((item: any, index: any) => {
                verificationStatsDict.current[`${item.speechId}|${item.level}`] = item.verificationStats
                // console.log(item, index);

                // let annotation_status = ''
                // if (item.annotation_status == "0_0") {
                //     annotation_status = "SENTENCE - MACHINE"
                // } else if (item.annotation_status.startsWith("0")) {
                //     annotation_status = `SENTENCE - HUMAN (${item.annotation_status.split('_')[1]})`
                // } else if (item.annotation_status == "1_0") {
                //     annotation_status = "PHONEME - MACHINE"
                // } else if (item.annotation_status.startsWith("1")) {
                //     annotation_status = `PHONEME - HUMAN (${item.annotation_status.split('_')[1]})`
                // } else if (item.annotation_status == "2_0") {
                //     annotation_status = "WORD - MACHINE"
                // } else if (item.annotation_status.startsWith("2")) {
                //     annotation_status = `WORD - HUMAN (${item.annotation_status.split('_')[1]})`
                // } else if (item.annotation_status == "3_0") {
                //     annotation_status = "ALIGN - MACHINE"
                // } else if (item.annotation_status.startsWith("3")) {isError
                //     annotation_status = `ALIGN - HUMAN (${item.annotation_status.split('_')[1]})`
                // }

                return userInfo.current.role == "annotator" ? {
                    speech_id: `${item.speechId}|${item.level}`,
                    duration: item.duration,
                    timeTaken: item.timeTaken,
                    annotatedDuration: item.annotatedDuration,

                    validated: item.validated,
                    validatedDuration: item.validatedDuration,
                    // overlappedDuration: item.overlappedDuration,

                    verified: item.verified,
                    verifiedDuration: item.verifiedDuration,
                    deductedDuration: item.deductedDuration,
                    acceptedDuration: item.acceptedDuration,

                    submittedOn: item.submittedOn,
                    billingStatus: item.billStatus == -1 ? "UNPAID" : item.billStatus == 0 ? "PROCESSING" : "PAID",
                    level: levels[item.level]
                } : {
                    speech_id: `${item.speechId}|${item.level}`,
                    duration: item.duration,
                    timeTaken: item.timeTaken,

                    validatedDuration: item.validatedDuration,
                    // overlappedDuration: item.overlappedDuration,

                    verified: item.verified,
                    verifiedDuration: item.verifiedDuration,
                    deductedDuration: item.deductedDuration,
                    acceptedDuration: item.acceptedDuration,

                    submittedOn: item.submittedOn,
                    billingStatus: item.billStatus == -1 ? "UNPAID" : item.billStatus == 0 ? "PROCESSING" : "PAID",
                    level: levels[item.level]
                }
            });

            // console.log("VERIFICATION STATS DICT: ", verificationStatsDict.current)

            userInfo.current.role == "annotator" ? setDataHistoryAnnotator(targetArray) : setDataHistoryValidator(targetArray);

            // setEligibleDataHistory(targetArray.filter((item: any) => {
            //     let submittedOn = new Date(item.submittedOn).getTime()
            //     let start = new Date(startDateTime).getTime()
            //     let end = new Date(endDateTime).getTime()
            //     return submittedOn >= start && submittedOn <= end
            // }));
        } else {
            //let error = await res.json()
            setIsLoading(false);
            setIsRefetching(false);
            setIsError(true);
        }
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: '#f06292', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => { }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <MyHeader />
                <div>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <Tabs value={tabValue} onChange={handleTabClick} textColor={tabValue == 0 ? 'primary' : 'secondary'} indicatorColor={tabValue == 0 ? 'primary' : 'secondary'} centered>
                                <Tab label={tabs[historyMode ? 2 : 0]} {...a11yProps(historyMode ? 2 : 0)} />
                                <Tab label={tabs[historyMode ? 3 : 1]} {...a11yProps(historyMode ? 3 : 1)} />
                            </Tabs> */}
                            <ToggleButton value="module" aria-label="module" sx={{ color: 'primary' }}>
                                {/* <DoneIcon /> */}
                                {userInfo.current['tts'] ? 'TTS' : 'STT'}
                            </ToggleButton>
                            <ToggleButton value="role" aria-label="role">
                                {userInfo.current['role'] == 'annotator' ? <DoneIcon color='secondary' /> : userInfo.current['role'] == 'validator' ? <DoneAllIcon color='secondary' /> : null}
                            </ToggleButton>
                        </Box>
                        {/* {[0, 1].map(idx => */}
                        {/* <TabPanel key={idx} value={tabValue} index={idx}> */}
                        {!historyMode ? (
                            <Box>
                                <MaterialReactTable
                                    columns={columnsTask}
                                    data={dataTask}
                                    // muiTableBodyRowProps={({ row }) => ({
                                    //   onClick: (event) => {
                                    //     console.log("ROW: ", event, row);
                                    //   },
                                    //   sx: {
                                    //     cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    //   },
                                    // })}
                                    renderTopToolbarCustomActions={() => (
                                        <Box
                                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                        >
                                            <Tooltip arrow title="Refresh Data">
                                                <IconButton onClick={() => loadDataTask()}>
                                                    <RefreshIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {userId && privilegedValidators.includes(userId) ?
                                                <Box>
                                                    <TextField id="annotator_id_tf" label="Annotator ID" variant="outlined" size='small'
                                                        spellCheck={false}
                                                        defaultValue={localStorage.getItem("targetAnnotatorId") ? localStorage.getItem("targetAnnotatorId") : ""}
                                                        onChange={(e: any) => {
                                                            setTargetAnnotatorId(e.target.value);
                                                        }}
                                                    // InputProps={{
                                                    //     startAdornment: (
                                                    //       <InputAdornment position="start">
                                                    //         <SearchIcon />
                                                    //       </InputAdornment>
                                                    //     ),
                                                    //   }}
                                                    />
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            renderInput={(props) => <TextField {...props} size="small" />}
                                                            label="FROM"
                                                            value={startDateTimePV}
                                                            minDateTime={dayjs(MIN_DATE)}
                                                            hideTabs={false}
                                                            // showToolbar={true}
                                                            onChange={(newValue: any) => {
                                                                if (newValue) {
                                                                    // console.log(newValue, newValue['$d'], typeof newValue['$d'])
                                                                    // console.log(new Date(newValue['$d']).toLocaleString());
                                                                    setStartDateTimePV(newValue);

                                                                    let dateStart = new Date(newValue['$d']);
                                                                    let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                                                                    localStorage.setItem('startDateTimePV', startDateTimeISO)

                                                                    // setEligibleDataHistory(dataHistory.filter((item: any) => {
                                                                    //     let submittedOn = new Date(item.submittedOn).getTime()
                                                                    //     let start = new Date(newValue['$d']).getTime()
                                                                    //     let end = new Date(endDateTime).getTime()
                                                                    //     return submittedOn >= start && submittedOn <= end
                                                                    // }))
                                                                }
                                                            }}
                                                        />
                                                        <DateTimePicker
                                                            renderInput={(props) => <TextField {...props} size="small" />}
                                                            label="TO"
                                                            value={endDateTimePV}
                                                            maxDateTime={dayjs(MAX_DATE)}
                                                            hideTabs={false}
                                                            onChange={(newValue: any) => {
                                                                if (newValue) {
                                                                    setEndDateTimePV(newValue);

                                                                    let dateEnd = new Date(newValue['$d']);
                                                                    let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                                                                    localStorage.setItem('endDateTimePV', endDateTimeISO)

                                                                    // setEligibleDataHistory(dataHistory.filter((item: any) => {
                                                                    //     let submittedOn = new Date(item.submittedOn).getTime()
                                                                    //     let start = new Date(startDateTime).getTime()
                                                                    //     let end = new Date(newValue['$d']).getTime()
                                                                    //     return submittedOn >= start && submittedOn <= end
                                                                    // }))
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    <Tooltip arrow title={showCurrentTimePV ? "Showing Current Time" : "Showing Last Selected"}>
                                                        {showCurrentTimePV ? <IconButton color='success' onClick={() => setShowCurrentTimePV(!showCurrentTimePV)}>
                                                            <UpdateIcon />
                                                        </IconButton> : <IconButton color='error' onClick={() => setShowCurrentTimePV(!showCurrentTimePV)}>
                                                            <UpdateDisabledIcon />
                                                        </IconButton>
                                                        }
                                                    </Tooltip>
                                                </Box> : null
                                            }
                                        </Box>
                                    )}

                                    muiTableBodyCellProps={({ cell }: any) => ({
                                        onClick: async (event) => {
                                            console.log("CELL: ", event, cell);
                                            let module = userInfo.current['tts'] == true ? "TTS" : "STT"
                                            if (cell.column.id == 'speech_id') {
                                                if (cell.getValue() != null) {
                                                    setLoading(true);
                                                    try {
                                                        let myHeaders = new Headers();
                                                        myHeaders.append("Content-Type", "application/json");

                                                        let requestOptions: RequestInit = {
                                                            method: 'POST',
                                                            headers: myHeaders,
                                                            body: JSON.stringify({
                                                                "annotator_id": userInfo.current.userId,
                                                                "speech_id": cell.getValue(),
                                                                "module": module,
                                                                "distribution": cell.row.original.distribution,
                                                                "distribution_source": cell.row.original.distribution_source
                                                            }),
                                                            cache: "no-cache"
                                                        };

                                                        let PICK_PORT = sttEvalAnnotators.includes(userInfo.current.userId) ? 5117 : 5105;

                                                        let res = await fetch(`https://amarkantho.revesoft.com:${PICK_PORT}/pick/`, requestOptions)
                                                        let data = await res.json();
                                                        setLoading(false);
                                                        if (res.status === 200 || res.status === 201) {
                                                            console.log("data: ", data);
                                                            if (data && !(data.alreadyPicked)) {
                                                                /*await loadForCurrentPage({
                                                                       ...filterQueries,
                                                                    page: response?.currentPage
                                                                })*/
                                                                ToastSuccessMsg(data.message);
                                                                //history.push(`/annotation/${item.id}`);
                                                                localStorage.setItem("last_picked", Math.round(new Date().getTime() / 1000).toString());
                                                                localStorage.setItem("speech_id", cell.getValue());
                                                                window.location.replace(window.location.origin + `/annotation`)
                                                                // return true;

                                                            } else {
                                                                ToastWarningMsg(data.message);
                                                                localStorage.setItem("speech_id", data["speech_id"]);
                                                                window.location.replace(window.location.origin + `/annotation`)
                                                                // return true;
                                                            }
                                                        }
                                                        else { //if (res.status == 403 ) {
                                                            ToastFailedMsg(data.message);
                                                            // return false;
                                                        }
                                                    } catch (err: any) {
                                                        setLoading(false);
                                                        ToastFailedMsg(err.message + '! Please check Internet connection ...');
                                                    }
                                                }
                                            }
                                        },
                                    })}
                                    // enableRowActions
                                    // renderRowActions={({ cell, row, table }) => {
                                    //     return (
                                    //         <Box>
                                    //             <IconButton color='success' onClick={(event) => console.log("ACCEPT")}>
                                    //                 <CheckIcon />
                                    //             </IconButton>
                                    //             <IconButton color='error' onClick={(event) => console.info('REJECT')}>
                                    //                 <ClearIcon />
                                    //             </IconButton>
                                    //         </Box>
                                    //     )
                                    // }
                                    // }

                                    // renderRowActionMenuItems={({ closeMenu, row, table }) => [
                                    //   <MenuItem onClick={() => console.info('Edit')}>Edit</MenuItem>,
                                    //   <MenuItem onClick={() => console.info('Delete')}>Delete</MenuItem>
                                    // ]}
                                    enableGlobalFilter={userId && privilegedValidators.includes(userId) ? true : false} //disable search feature
                                    globalFilterFn="contains"

                                    enableStickyHeader
                                    enableStickyFooter
                                    // muiTableContainerProps={{ sx: { maxHeight: '300px' } }}

                                    //optionally override the default column widths
                                    // defaultColumn={{
                                    //     maxSize: 400,
                                    //     minSize: 80,
                                    //     size: 150, //default size is usually 180
                                    // }}
                                    enableColumnResizing
                                    columnResizeMode="onChange" //default is "onEnd"
                                    enableColumnOrdering

                                    positionActionsColumn="last"
                                    // enableRowSelection
                                    enablePinning
                                    enableColumnFilterModes
                                    // enableHiding

                                    // filterFns={{
                                    //     customFilterFn: (row, id, filterValue) => {
                                    //         return row.getValue(id) === filterValue;
                                    //     },
                                    // }}
                                    // localization={
                                    //     {
                                    //         filterCustomFilterFn: 'Custom Filter Fn',
                                    //     } as any
                                    // }

                                    // renderDetailPanel={({ row }) => (
                                    //     <Box
                                    //         sx={{
                                    //             display: 'grid',
                                    //             margin: 'auto',
                                    //             gridTemplateColumns: '1fr 1fr',
                                    //             width: '100%',
                                    //         }}
                                    //     >
                                    //         <Typography>Annotator ID: {row.original.speech_id}</Typography>
                                    //         <Typography>Validator ID: {row.original.speech_id}</Typography>
                                    //     </Box>
                                    // )}
                                    // positionExpandColumn="first"

                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                color: 'error',
                                                children: 'Error loading data',
                                            }
                                            : undefined
                                    }
                                    onColumnVisibilityChange={setColumnVisibility}
                                    onColumnFiltersChange={setColumnFilters}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onShowGlobalFilterChange={setShowGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}

                                    state={{
                                        columnVisibility,
                                        columnFilters,
                                        globalFilter,
                                        showGlobalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching || isLoading,
                                        sorting,
                                    }}

                                    initialState={{
                                        showColumnFilters: false,
                                        columnVisibility: {
                                            distribution: false,
                                            distribution_source: false,
                                            no_of_times_annotated: false
                                        },
                                        columnPinning: { left: ['speech_id'] },
                                        density: 'compact',
                                    }}

                                    positionToolbarAlertBanner="bottom"
                                //   renderTopToolbarCustomActions={({ table }) => (
                                //     <Box
                                //       sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                //     >
                                //       <Button
                                //         color="primary"
                                //         //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                //         onClick={handleExportData}
                                //         startIcon={<FileDownloadIcon />}
                                //         variant="contained"
                                //       >
                                //         Export All Data
                                //       </Button>
                                //       <Button
                                //         disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //         //export all rows, including from the next page, (still respects filtering and sorting)
                                //         onClick={() =>
                                //           handleExportRows(table.getPrePaginationRowModel().rows)
                                //         }
                                //         startIcon={<FileDownloadIcon />}
                                //         variant="contained"
                                //       >
                                //         Export All Rows
                                //       </Button>
                                //       <Button
                                //         disabled={table.getRowModel().rows.length === 0}
                                //         //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                //         onClick={() => handleExportRows(table.getRowModel().rows)}
                                //         startIcon={<FileDownloadIcon />}
                                //         variant="contained"
                                //       >
                                //         Export Page Rows
                                //       </Button>
                                //       <Button
                                //         disabled={
                                //           !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                //         }
                                //         //only export selected rows
                                //         onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                //         startIcon={<FileDownloadIcon />}
                                //         variant="contained"
                                //       >
                                //         Export Selected Rows
                                //       </Button>
                                //     </Box>
                                //   )}
                                /></Box>) : (
                            userInfo.current.role == "annotator" ? <Box>
                                <MaterialReactTable
                                    columns={columnsHistoryAnnotator}
                                    data={dataHistoryAnnotator}
                                    enableGrouping
                                    // muiTableBodyRowProps={({ row }) => ({
                                    //   onClick: (event) => {
                                    //     console.log("ROW: ", event, row);
                                    //   },
                                    //   sx: {
                                    //     cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    //   },
                                    // })}
                                    renderTopToolbarCustomActions={() => (
                                        <Box
                                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                        >
                                            <Tooltip arrow title="Refresh Data">
                                                <IconButton onClick={() => loadDataHistory()}>
                                                    <RefreshIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="FROM"
                                                    value={startDateTime}
                                                    minDateTime={dayjs(MIN_DATE)}
                                                    hideTabs={false}
                                                    // showToolbar={true}
                                                    onChange={(newValue: any) => {
                                                        if (newValue) {
                                                            // console.log(newValue, newValue['$d'], typeof newValue['$d'])
                                                            // console.log(new Date(newValue['$d']).toLocaleString());
                                                            setStartDateTime(newValue);

                                                            let dateStart = new Date(newValue['$d']);
                                                            let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                                                            localStorage.setItem('startDateTime', startDateTimeISO)

                                                            // setEligibleDataHistory(dataHistory.filter((item: any) => {
                                                            //     let submittedOn = new Date(item.submittedOn).getTime()
                                                            //     let start = new Date(newValue['$d']).getTime()
                                                            //     let end = new Date(endDateTime).getTime()
                                                            //     return submittedOn >= start && submittedOn <= end
                                                            // }))
                                                        }
                                                    }}
                                                />
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="TO"
                                                    value={endDateTime}
                                                    maxDateTime={dayjs(MAX_DATE)}
                                                    hideTabs={false}
                                                    onChange={(newValue: any) => {
                                                        if (newValue) {
                                                            setEndDateTime(newValue);

                                                            let dateEnd = new Date(newValue['$d']);
                                                            let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                                                            localStorage.setItem('endDateTime', endDateTimeISO)

                                                            // setEligibleDataHistory(dataHistory.filter((item: any) => {
                                                            //     let submittedOn = new Date(item.submittedOn).getTime()
                                                            //     let start = new Date(startDateTime).getTime()
                                                            //     let end = new Date(newValue['$d']).getTime()
                                                            //     return submittedOn >= start && submittedOn <= end
                                                            // }))
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <Tooltip arrow title={showCurrentTime ? "Showing Current Time" : "Showing Last Selected"}>
                                                {showCurrentTime ? <IconButton color='success' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                                                    <UpdateIcon />
                                                </IconButton> : <IconButton color='error' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                                                    <UpdateDisabledIcon />
                                                </IconButton>
                                                }
                                            </Tooltip>
                                        </Box>
                                    )}

                                    muiTableBodyCellProps={({ cell }: any) => ({
                                        onClick: async (event) => {
                                            console.log("CELL: ", event, cell);
                                            // let module = tabValue == 0 ? "ANNOTATION" : "VALIDATION"
                                            // if (cell.column.id == 'speech_id') {
                                            //     try {
                                            //         let myHeaders = new Headers();
                                            //         myHeaders.append("Content-Type", "application/json");

                                            //         let requestOptions: RequestInit = {
                                            //             method: 'POST',
                                            //             headers: myHeaders,
                                            //             body: JSON.stringify({
                                            //                 "annotator_id": userInfo.current.userId,
                                            //                 "speech_id": cell.getValue(),
                                            //                 "module": module,
                                            //                 "distribution": cell.row.original.distribution
                                            //             }),
                                            //             cache: "no-cache"
                                            //         };

                                            //         let res = await fetch("https://amarkantho.revesoft.com:5105/pick/", requestOptions)
                                            //         let data = await res.json();
                                            //         if (res.status === 200 || res.status === 201) {
                                            //             console.log("data: ", data);
                                            //             if (data && !(data.alreadyPicked)) {
                                            //                 /*await loadForCurrentPage({
                                            //                        ...filterQueries,
                                            //                     page: response?.currentPage
                                            //                 })*/
                                            //                 ToastSuccessMsg(data.message);
                                            //                 //history.push(`/annotation/${item.id}`);
                                            //                 localStorage.setItem("speech_id", cell.getValue());
                                            //                 window.location.replace(window.location.origin + `/annotation`)
                                            //                 // return true;

                                            //             } else {
                                            //                 ToastWarningMsg(data.message);
                                            //                 localStorage.setItem("speech_id", data["speech_id"]);
                                            //                 // return true;
                                            //             }
                                            //         }
                                            //         else { //if (res.status == 403 ) {
                                            //             ToastFailedMsg(data.message);
                                            //             // return false;
                                            //         }
                                            //     } catch (err: any) {
                                            //         ToastFailedMsg(err.message + '! Please check Internet connection ...');
                                            //     }
                                            // }
                                        },
                                    })}
                                    // enableRowActions
                                    // renderRowActions={({ cell, row, table }) => {
                                    //     return (
                                    //         <Box>
                                    //             <IconButton color='success' onClick={(event) => console.log("ACCEPT")}>
                                    //                 <CheckIcon />
                                    //             </IconButton>
                                    //             <IconButton color='error' onClick={(event) => console.info('REJECT')}>
                                    //                 <ClearIcon />
                                    //             </IconButton>
                                    //         </Box>
                                    //     )
                                    // }
                                    // }

                                    // renderRowActionMenuItems={({ closeMenu, row, table }) => [
                                    //   <MenuItem onClick={() => console.info('Edit')}>Edit</MenuItem>,
                                    //   <MenuItem onClick={() => console.info('Delete')}>Delete</MenuItem>
                                    // ]}

                                    enableStickyHeader
                                    enableStickyFooter
                                    // muiTableContainerProps={{ sx: { maxHeight: '300px' } }}

                                    //optionally override the default column widths
                                    // defaultColumn={{
                                    //     maxSize: 400,
                                    //     minSize: 80,
                                    //     size: 150, //default size is usually 180
                                    // }}
                                    enableColumnResizing
                                    columnResizeMode="onChange" //default is "onEnd"
                                    enableColumnOrdering


                                    positionActionsColumn="last"
                                    // enableRowSelection
                                    enablePinning
                                    enableColumnFilterModes
                                    globalFilterFn="contains"
                                    // enableHiding

                                    // filterFns={{
                                    //     customFilterFn: (row, id, filterValue) => {
                                    //         return row.getValue(id) === filterValue;
                                    //     },
                                    // }}
                                    // localization={
                                    //     {
                                    //         filterCustomFilterFn: 'Custom Filter Fn',
                                    //     } as any
                                    // }

                                    // renderDetailPanel={({ row }) => (
                                    //     <Box
                                    //         sx={{
                                    //             display: 'grid',
                                    //             margin: 'auto',
                                    //             gridTemplateColumns: '1fr 1fr',
                                    //             width: '100%',
                                    //         }}
                                    //     >
                                    //         <Typography>Annotator ID: {row.original.speech_id}</Typography>
                                    //         <Typography>Validator ID: {row.original.speech_id}</Typography>
                                    //     </Box>
                                    // )}
                                    // positionExpandColumn="first"

                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                color: 'error',
                                                children: 'Error loading data',
                                            }
                                            : undefined
                                    }
                                    onColumnVisibilityChange={setColumnVisibilityHistory}
                                    onColumnFiltersChange={setColumnFiltersHistory}
                                    onGlobalFilterChange={setGlobalFilterHistory}
                                    onPaginationChange={setPaginationHistory}
                                    onSortingChange={setSortingHistory}

                                    state={{
                                        columnVisibility: columnVisibilityHistory,
                                        columnFilters: columnFiltersHistory,
                                        globalFilter: globalFilterHistory,
                                        sorting: sortingHistory,
                                        pagination: paginationHistory,
                                        isLoading,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching || isLoading,
                                    }}

                                    initialState={{
                                        showColumnFilters: false,
                                        columnPinning: { left: ['speech_id'] },
                                        density: 'compact',
                                        // grouping: ['billingStatus'], //an array of columns to group by by default (can be multiple)
                                    }}

                                    positionToolbarAlertBanner="bottom"
                                /></Box> : <Box>
                                <MaterialReactTable
                                    columns={columnsHistoryValidator}
                                    data={dataHistoryValidator}
                                    enableGrouping
                                    // muiTableBodyRowProps={({ row }) => ({
                                    //   onClick: (event) => {
                                    //     console.log("ROW: ", event, row);
                                    //   },
                                    //   sx: {
                                    //     cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    //   },
                                    // })}
                                    renderTopToolbarCustomActions={() => (
                                        <Box
                                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                        >
                                            <Tooltip arrow title="Refresh Data">
                                                <IconButton onClick={() => loadDataHistory()}>
                                                    <RefreshIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="FROM"
                                                    value={startDateTime}
                                                    minDateTime={dayjs(MIN_DATE)}
                                                    hideTabs={false}
                                                    // showToolbar={true}
                                                    onChange={(newValue: any) => {
                                                        if (newValue) {
                                                            // console.log(newValue, newValue['$d'], typeof newValue['$d'])
                                                            // console.log(new Date(newValue['$d']).toLocaleString());
                                                            setStartDateTime(newValue);

                                                            let dateStart = new Date(newValue['$d']);
                                                            let startDateTimeISO = new Date(dateStart.getTime() - (dateStart.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                                                            localStorage.setItem('startDateTime', startDateTimeISO)
                                                        }
                                                    }}
                                                />
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="TO"
                                                    value={endDateTime}
                                                    maxDateTime={dayjs(MAX_DATE)}
                                                    hideTabs={false}
                                                    onChange={(newValue: any) => {
                                                        if (newValue) {
                                                            setEndDateTime(newValue);

                                                            let dateEnd = new Date(newValue['$d']);
                                                            let endDateTimeISO = new Date(dateEnd.getTime() - (dateEnd.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
                                                            localStorage.setItem('endDateTime', endDateTimeISO)
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <Tooltip arrow title={showCurrentTime ? "Showing Current Time" : "Showing Last Selected"}>
                                                {showCurrentTime ? <IconButton color='success' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                                                    <UpdateIcon />
                                                </IconButton> : <IconButton color='error' onClick={() => setShowCurrentTime(!showCurrentTime)}>
                                                    <UpdateDisabledIcon />
                                                </IconButton>
                                                }
                                            </Tooltip>
                                        </Box>
                                    )}

                                    muiTableBodyCellProps={({ cell }: any) => ({
                                        onClick: async (event) => {
                                            console.log("CELL: ", event, cell);
                                        },
                                    })}

                                    enableStickyHeader
                                    enableStickyFooter

                                    enableColumnResizing
                                    columnResizeMode="onChange" //default is "onEnd"
                                    enableColumnOrdering


                                    positionActionsColumn="last"
                                    // enableRowSelection
                                    enablePinning
                                    enableColumnFilterModes
                                    globalFilterFn="contains"

                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                color: 'error',
                                                children: 'Error loading data',
                                            }
                                            : undefined
                                    }
                                    onColumnVisibilityChange={setColumnVisibilityHistory}
                                    onColumnFiltersChange={setColumnFiltersHistory}
                                    onGlobalFilterChange={setGlobalFilterHistory}
                                    onPaginationChange={setPaginationHistory}
                                    onSortingChange={setSortingHistory}

                                    state={{
                                        columnVisibility: columnVisibilityHistory,
                                        columnFilters: columnFiltersHistory,
                                        globalFilter: globalFilterHistory,
                                        sorting: sortingHistory,
                                        pagination: paginationHistory,
                                        isLoading,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching || isLoading,
                                    }}

                                    initialState={{
                                        showColumnFilters: false,
                                        columnPinning: { left: ['speech_id'] },
                                        density: 'compact',
                                        // grouping: ['billingStatus'], //an array of columns to group by by default (can be multiple)
                                    }}

                                    positionToolbarAlertBanner="bottom"
                                />
                            </Box>
                        )}
                        {/* </TabPanel> */}
                        {/* )} */}

                        {/* <TabPanel value={value} index={1}>
                            Item Two
                        </TabPanel> */}
                    </Box>
                    {/* <div className="mt-4 pl-4 container-fluid">
                <BootstrapTable keyField='id' data={response} columns={columns} rowEvents={rowEvents} filter={filterFactory()} pagination={paginationFactory(paginationOptions)} />
            </div> */}
                </div>
                {/* <Button onClick={()=>speak({text: "Hello World", voice: voices[1]})}>Hello</Button> */}
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
};

export default AnnotatorTaskPage;

